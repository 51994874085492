import { useModal, IconButton } from '@pancakeswap/uikit'
import TransactionsModal from './TransactionsModal'
import { HistoryIcon } from '../../../../public/icon'
import React from 'react'

const Transactions = () => {
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)
  return (
    <>
      <IconButton style={{ background: 'transparent' }} scale="md" variant="text" onClick={onPresentTransactionsModal}>
        <HistoryIcon />
      </IconButton>
    </>
  )
}

export default Transactions
