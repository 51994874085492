import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={6} cy={6} r={6} fill="#1DAB87" />
      <g clipPath="url(#clip0_334_2932)">
        <path
          d="M3.958 6l1.458 1.458 2.917-2.916"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_334_2932">
          <path fill="#fff" transform="translate(2.5 2.5)" d="M0 0H7V7H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;
