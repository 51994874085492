/* eslint-disable import/no-duplicates */
import styled from 'styled-components'
import { CloseIcon, LinkExternal, Flex, Text, Box, CheckmarkCircleFillIcon, NewCheckFillIcon } from '@pancakeswap/uikit'
import { getBlockExploreLink } from '../../../utils'
import { TransactionDetails } from 'state/transactions/reducer'
import CircleLoader from '../../Loader/CircleLoader'
import historyTransaction from '../../../style/HistoryTransaction.module.css'

import Image from 'next/image'

const TransactionState = styled.div<{ pending: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.25rem 0rem;
  font-weight: 500;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.colors.primary};
`

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) =>
    pending ? theme.colors.primary : success ? theme.colors.success : theme.colors.failure};
`

const symbolToken = {
  swich: '/images/transaction/swich.png',
  usdt: '/images/transaction/usdt.png',
  babyDoge: '/images/transaction/babyDoge.png',
  floki: '/images/transaction/floki.png',
  link: '/images/transaction/link.png',
  wbnb: '/images/transaction/wbnb.png',
  weth: '/images/transaction/weth.png',
  wbtc: '/images/transaction/wbtc.png',
  tbnb: '/images/transaction/tbnb.png',
  cra: '/images/transaction/cra.png',
}

const getInputImage = (symbol: string | undefined): JSX.Element | null => {
  const lowercasedSymbol = symbol?.toLocaleString().toLowerCase()
  return symbolToken[lowercasedSymbol] ? (
    <Image alt="images" src={symbolToken[lowercasedSymbol]} width={32} height={32} />
  ) : null
}

export default function Transaction({ tx, chainId }: { tx: TransactionDetails; chainId: number }) {
  const summary = tx?.summary
  const pending = !tx?.receipt
  const success = !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined')

  if (!chainId) return null

  const getTimeCategory = (timestamp: number): string => {
    const date = new Date(timestamp)
    const today = new Date()

    // Check if the date is today
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return 'Today'
    }

    // Check if the date is within the current week
    const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
    const timeDifference = Math.round(Math.abs((today.getTime() - date.getTime()) / oneDay))
    if (timeDifference <= 6 && date.getDay() >= today.getDay()) {
      return 'This week'
    }

    // If not today or this week, consider it older
    return 'Older'
  }

  return (
    <TransactionState
      // style={{
      //   border: '1px solid rgba(255,255,255,0.05)',
      //   padding: '12px',
      //   marginBottom: '20px',
      //   borderRadius: '12px',
      //   background: '#191F39',
      // }}
      pending={pending}
      success={success}
    >
      {/* <LinkExternal href={getBlockExploreLink(tx.hash, 'transaction', chainId)}>{summary ?? tx.hash}</LinkExternal>
      <IconWrapper pending={pending} success={success}>
        {pending ? <CircleLoader /> : success ? <CheckmarkIcon color="success" /> : <CloseIcon color="failure" />}
      </IconWrapper> */}
      <div className={historyTransaction.detailWrapper}>
        <div className={historyTransaction.wrapperTransaction}>
          {tx.type.toLowerCase() === 'wrap' ? (
            <div className={historyTransaction.fronImage}>
              {getInputImage(tx?.translatableSummary?.data?.wrap as string)}
            </div>
          ) : (
            <div className={historyTransaction.stackedImage}>
              <div className={historyTransaction.backImage}>
                {getInputImage(
                  tx?.translatableSummary?.data?.inputSymbol
                    ? String(tx?.translatableSummary?.data?.inputSymbol)
                    : String(tx?.translatableSummary?.data?.symbolA),
                )}
              </div>
              <div className={historyTransaction.fronImage} style={{ zIndex: 2 }}>
                {getInputImage(
                  tx?.translatableSummary?.data?.outputSymbol
                    ? String(tx?.translatableSummary?.data?.outputSymbol)
                    : String(tx?.translatableSummary?.data?.symbolB),
                )}
              </div>
            </div>
          )}
          <Flex flexDirection="column" gap="1px">
            {/* <h1
              style={{
                fontSize: '12px',
                color: '#90A3BF',
              }}
            >
              {getTimeCategory(tx.confirmedTime)}
            </h1> */}

            <Text textTransform="capitalize" fontSize={14} fontWeight={600} color="#FBFCFF">
              {tx.type}
            </Text>
            <Text fontSize={12} fontWeight={400} color="#ADB1B8">
              {pending ? 'Please confirm in Metamask' : 'Transaction Success'}
            </Text>
          </Flex>
        </div>
        <IconWrapper pending={pending} success={success}>
          {pending ? (
            <CircleLoader />
          ) : success ? (
            <NewCheckFillIcon color="success" fill="contrast" />
          ) : (
            <CloseIcon color="failure" />
          )}
        </IconWrapper>
      </div>
      {/* <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Flex justifyContent="space-between">
          {tx.translatableSummary?.data?.inputSymbol.toLocaleString().toLowerCase() === 'tbsc' ? (
            <Image alt="images" src="/images/chains/575757.png" width={10} height={50} priority />
          ) : null}
          <Flex flexDirection="column" gap="4px">
            
            <Text textTransform="capitalize" fontSize={16} fontWeight={600} color="textSubtle">
              {tx.type}
            </Text>
            <Text fontSize={16} fontWeight={400} color="textSubtle2">
              {pending ? 'Please confirm in Metamask' : 'Transaction Success'}
            </Text>
          </Flex>
        </Flex>

        <IconWrapper pending={pending} success={success}>
          {pending ? (
            <CircleLoader />
          ) : success ? (
            <CheckmarkCircleFillIcon color="success" fill="contrast" />
          ) : (
            <CloseIcon color="failure" />
          )}
        </IconWrapper>
      </Flex> */}
    </TransactionState>
  )
}
