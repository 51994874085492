import styled from 'styled-components'
import { Card } from '@pancakeswap/uikit'

export const BodyWrapper = styled(Card)`
  border-radius: 24px;
  max-width: 1200px;
  width: 100%;
  z-index: 1;
  background: #1a1a1a !important;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05);
  padding: 16px;
  margin: 0 ${({ theme }) => theme.mediaQueries.lg} {
    margin: 0 24px;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 24px;
    padding-top: 2px !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    background: linear-gradient(
      to bottom,
      rgba(244, 118, 33, 0.1) 0%,
      rgba(244, 118, 33, 0.7) 24%,
      rgba(244, 118, 33, 0)
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none !important;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
